import React, { useState, useEffect, useContext } from 'react';
import { Button, Form, Input, Spin, notification } from 'antd';
import { UserContext } from '../../../contexts/userContext';
import TargetJob from '../../../components/Modals/WelcomeModal/NewSteps/StepComponents/Target-Job';
import JobDetails from '../../../components/Modals/WelcomeModal/NewSteps/StepComponents/JobDetails';
import { useQuery } from '@apollo/client';
import { QUERY_ALL_JOBS } from '../../../utils/queries';
import { useMutation } from '@apollo/client';
import { SET_WELCOME_WIZARD } from '../../../utils/mutations';
import { ADD_JOB } from '../../../utils/mutations';



const JobPreferences = () => {
    const { user, refetchUser } = useContext(UserContext);
    const { data: jobData, refetch: refetchJobs } = useQuery(QUERY_ALL_JOBS);
    const [allJobs, setAllJobs] = useState([]);
    const [userData, setUserData] = useState({
        targetJob: user.targetJob ? user.targetJob : undefined,
        yearsExperience: user.yearsExperience ? user.yearsExperience : null,
        targetJobLevel: user.targetJobLevel ? user.targetJobLevel : "",
        targetCompanies: user.targetCompanies ? user.targetCompanies.filter(company => company != null).map(company => company._id) : [],
        targetLocation: user.targetLocation ? (({ __typename, ...rest }) => rest)(user.targetLocation) : null,
    });
    const [setWelcomeWizard] = useMutation(SET_WELCOME_WIZARD);
    const [addJob] = useMutation(ADD_JOB);

    useEffect(() => {
        if (jobData) {
            setAllJobs(jobData);
        }
    }, [jobData]);

    console.log(allJobs);


    if (!user) {
        return <Spin />;
    }



    const handleFormSubmit = async () => {
        console.log('userData:', userData);

        try {
            if (userData.customTargetJob && userData.targetJobField) {
                // Add New Job to DB
                const newCustomJob = await addJob({
                    variables: {
                        title: userData.customTargetJob,
                        jobFields: userData.targetJobField._id,
                        custom: true,
                    },
                });

                setUserData(prevState => {
                    const { customTargetJob, targetJobField, ...rest } = prevState;
                    return {
                        ...rest,
                        targetJob: newCustomJob.data.addJob,
                    };
                });

                console.log('Refetching Jobs...');
                await refetchJobs();

                // Update User DB
                await setWelcomeWizard({
                    variables: {
                        targetJob: newCustomJob.data.addJob._id,
                        yearsExperience: userData.yearsExperience,
                        targetJobLevel: userData.targetJobLevel,
                        targetCompanies: userData.targetCompanies,
                        targetLocation: userData.targetLocation,
                    },
                });
            } else {
                // Update User DB without adding a new job
                await setWelcomeWizard({
                    variables: {
                        targetJob: userData.targetJob._id,
                        yearsExperience: userData.yearsExperience,
                        targetJobLevel: userData.targetJobLevel,
                        targetCompanies: userData.targetCompanies,
                        targetLocation: userData.targetLocation,
                    },
                });
            }

            // Show success notification
            notification.success({
                message: 'Success',
                description: 'Job Preferences have been updated.',
            });

        } catch (err) {
            console.error('Error adding custom job or updating user:', err);
            notification.error({
                message: 'Error',
                description: 'There was an error updating your job preferences.',
            });
        }
    };



    return (
        <div style={{ margin: '10px' }}>
            <h2>Job Preferences</h2>
    
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '0px', maxWidth: '600px', width: '100%' }}>
                <TargetJob
                    jobData={allJobs}
                    userData={userData}
                    setUserData={setUserData}
                    setStep={() => { }} // Dummy function
                    disableNext={() => { }} // Dummy function
                    doNotDisplayOptions={true}
                />
    
                <JobDetails
                    jobData={allJobs}
                    userData={userData}
                    setUserData={setUserData}
                    setStep={() => { }} // Dummy function
                    disableNext={() => { }} // Dummy function
                />
    
                <div style={{ display: 'flex', justifyContent: 'center', width: '100%', marginTop: '20px' }}>
                    <Button type="primary" className='button-color' onClick={handleFormSubmit}>
                        Update
                    </Button>
                </div>
            </div>
        </div>
    );

}


export default JobPreferences;

