import axios from 'axios';



export const title_feedback = "Feedback";

export const defaultFeedbackSubject = "Feedback Request";

export function defaultFeedbackMessage(Name, Position, Company) {
    if (!Name) {
        Name = "[Name]";
    }

    return `Hi ${Name},

I recently received the news about not being selected for the ${Position} position at ${Company}. While I am disappointed, I appreciate being considered for the role.

I would be grateful if you could provide any feedback from my interview or application that could help me improve for future opportunities. I am always looking to grow and improve, and your insights would be invaluable.

Thank you once again for considering my application and for your time.

Best regards,
[Your Name]
`;
}

export const additionalInfoFeedbackPlaceholder = "Add any details on why you are asking for feedback";

export const fetchFeedbackMessage = async (Position, Company, AdditionalInfo, currentContact, coldOutreach, linkedInCharacterLimit) => {
    const data = {
        Position: Position,
        Company: Company,
        AdditionalInfo: AdditionalInfo,
        Name: currentContact,
        Cold: coldOutreach,
        LinkLimit: linkedInCharacterLimit,
        MessageType: "Feedback"
    };
    try {
        const response = await axios.post('/message/applicationMessage', data);

        return response.data.applicationMessage;
    } catch (error) {
        console.error("There was a problem with the fetch operation:", error.message);
    }
}
