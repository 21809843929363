import axios from 'axios';


//Backgroud
export const startFindConnectionsTask = async (company, userID) => {

    const data = {
        companyName: company,
        userID: userID
    };

    //Or send complete company object if available
    // const data = {
    //     company: company
    // };

    try {

        const response = await axios.post('/connections/find-connections-task', data);

        return response;

    } catch (error) {

        if (error.response && error.response.status === 403) {
            throw new Error('Subscription limit exceeded');
        } else {
            throw new Error('Error finding connections');
        }

    }
}


export const getFindConnectionsTaskStatus = async (taskId) => {

    try {

        const response = await axios.get(`/connections/find-connections-task/${taskId}/status`);

        return response;

    } catch (error) {

        console.error("There was a problem getting the status of the find connections task", error.message);

    }
}


export const getFindConnectionsTaskResult = async (taskId) => {

    try {

        const response = await axios.get(`/connections/find-connections-task/${taskId}/result`);

        return response;

    } catch (error) {

        console.error("There was a problem getting the result of the find connections task", error.message);

    }
}