import React, { useState, useEffect } from 'react';
import { Card, Space, Typography, AutoComplete, Button, Select, List, Tabs, Flex, Tag, Divider } from 'antd';
import { useQuery } from '@apollo/client';
import { QUERY_ALL_JOB_FIELDS } from '../../../../../utils/queries';
import './TargetJob.css';

const { Title, Text } = Typography;


const TargetJob = ({ jobData, userData, setUserData, setStep, disableNext, doNotDisplayOptions }) => {
    const [selectedJob, setSelectedJob] = useState(userData.targetJob);
    const [showJobFieldsParents, setShowJobFieldsParents] = useState(false);
    const [showJobFields, setShowJobFields] = useState(false);
    const [selectedJobField, setSelectedJobField] = useState('');
    const [selectedParent, setSelectedParent] = useState('');
    const { data: jobFieldsData } = useQuery(QUERY_ALL_JOB_FIELDS);
    const [jobFieldsParents, setJobFieldsParents] = useState([]);
    const [filteredJobFields, setFilteredJobFields] = useState([]);
    const jobFields = jobFieldsData ? jobFieldsData.allJobFields : [];

    useEffect(() => {
        if (jobFieldsData) {
            const parents = jobFieldsData.allJobFields.map(field => field.Parent);
            const uniqueParents = [...new Set(parents)];
            setJobFieldsParents(uniqueParents);
        }
    }, [jobFieldsData]);


    useEffect(() => {
        if (!selectedJob) {
            disableNext(true);
        } else {
            disableNext(false);
        }
    }, []);

    const updateUserData_TargetJob = (value) => {

        setSelectedJob({ title: value });

        const selectedJobObject = jobData.allJobs.find(job => job.title === value);

        if (!selectedJobObject) {
            setShowJobFieldsParents(true);
            return
        } else {
            setShowJobFieldsParents(false);
            setShowJobFields(false);
        }

        setUserData({
            ...userData,
            targetJob: selectedJobObject,
        });

        disableNext(false);
    }

    const handleSelectJobField = (value) => {

        console.log(selectedJob);

        setSelectedJobField(value);

        const selectedJobFieldObject = jobFields.find(field => field.Field === value);

        if (!selectedJobFieldObject) {
            setFilteredJobFields(jobFields.filter(field => field.Parent === value));
            setSelectedParent(value);
            setShowJobFields(true);
            return
        }

        if (jobFieldsParents.includes(value)) {
            setShowJobFields(false);
        }

        setUserData({
            ...userData,
            customTargetJob: selectedJob.title,
            targetJobField: selectedJobFieldObject,
        });

        disableNext(false);
    }

    const jobCategories = [
        {
            category: 'Sell-Side',
            jobs: [
                { title: 'Investment Banker' },
                { title: 'Equity Research Analyst' },
                { title: 'Sales and Trading Analyst' },
                { title: 'Financial Analyst' },
                { title: 'Accountant' },
                { title: 'Auditor' },
                { title: 'Treasury Analyst' },
                { title: 'Credit Analyst' },
                { title: 'Risk Manager' },
                { title: 'Tax Advisor' },
                { title: 'Mergers and Acquisitions Analyst' },
                { title: 'Corporate Finance Analyst' },
            ],
        },
        {
            category: 'Buy-Side',
            jobs: [
                { title: 'Portfolio Manager' },
                { title: 'Hedge Fund Analyst' },
                { title: 'Private Equity Associate' },
                { title: 'Venture Capital Analyst' },
                { title: 'Asset Manager' },
                { title: 'Mutual Fund Analyst' },
                { title: 'Investment Analyst' },
                { title: 'Quantitative Analyst' },
                { title: 'Research Analyst' },
                { title: 'Trader' },
                { title: 'Wealth Manager' },
                { title: 'Fund Manager' },
            ],
        },
        {
            category: 'Consulting',
            jobs: [
                { title: 'Management Consultant' },
                { title: 'Strategy Consultant' },
                { title: 'Financial Consultant' },
                { title: 'IT Consultant' },
                { title: 'HR Consultant' },
                { title: 'Operations Consultant' },
                { title: 'Marketing Consultant' },
                { title: 'Sales Consultant' },
                { title: 'Risk Consultant' },
                { title: 'Compliance Consultant' },
                { title: 'Environmental Consultant' },
                { title: 'Healthcare Consultant' },
                { title: 'Business Consultant' },
                { title: 'Technology Consultant' },
            ],
        },
        {
            category: 'Marketing',
            jobs: [
                { title: 'Marketing Analyst' },
                { title: 'Brand Manager' },
                { title: 'Market Research Analyst' },
                { title: 'Digital Marketing Specialist' },
                { title: 'SEO Specialist' },
                { title: 'Content Strategist' },
                { title: 'Social Media Manager' },
                { title: 'Product Marketing Manager' },
                { title: 'Public Relations Specialist' },
                { title: 'Advertising Manager' },
            ],
        },
        {
            category: 'Sales and Management',
            jobs: [
                { title: 'Sales Representative' },
                { title: 'Leadership Development' },
                { title: 'Account Manager' },
                { title: 'Business Development Representative' },
                { title: 'Project Manager' },
                { title: 'Product Manager' },
            ],
        },
        {
            category: 'Technology',
            jobs: [
                { title: 'Full-Stack Developer' },
                { title: 'Frontend Developer' },
                { title: 'Backend Developer' },
                { title: 'Blockchain Developer' },
                { title: 'Product Manager' },
                { title: 'Data Scientist' },
                { title: 'AI/ML Engineer' },
                { title: 'Software Developer' },
                { title: 'Systems Analyst' },
                { title: 'Cybersecurity Analyst' },
                { title: 'DevOps Engineer' },
                { title: 'Cloud Architect' },
            ],
        },
        {
            category: 'Data Analytics',
            jobs: [
                { title: 'Data Analyst' },
                { title: 'Data Scientist' },
                { title: 'Business Intelligence Analyst' },
                { title: 'Data Engineer' },
                { title: 'Quantitative Analyst' },
                { title: 'Data Architect' },
                { title: 'Machine Learning Engineer' },
                { title: 'Operations Analyst' },
                { title: 'Marketing Analyst' },
            ],
        },
        {
            category: 'Law',
            jobs: [
                { title: 'Lawyer' },
                { title: 'Paralegal' },
                { title: 'Judge' },
                { title: 'Legal Assistant' },
                { title: 'Corporate Counsel' },
                { title: 'Public Defender' },
                { title: 'Prosecutor' },
                { title: 'Legal Analyst' },
                { title: 'Compliance Officer' },
                { title: 'Mediator' },
            ],
        }
    ];


    const onChange = (key) => {
        console.log(key);
    };

    return (
        <div className="target-job-container">
            <div className="target-job-content">
                <div className="job-details">
                    <Space direction="vertical" size="large">
                        <Title level={3}>Target Job</Title>
                        <Text>Select or type your target job title to get started</Text>
                        <Space>
                            <AutoComplete
                                options={
                                    jobData && jobData.allJobs ? jobData.allJobs.map((job, _) => ({
                                        value: job.title,
                                        label: job.title,
                                    })) : []
                                }
                                value={selectedJob ? selectedJob.title : ''}
                                onChange={(value) => updateUserData_TargetJob(value)}
                                style={{ width: 300 }}
                                filterOption={(inputValue, option) =>
                                    option.value && option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                }
                            />
                        </Space>

                        {showJobFieldsParents && (
                            <>
                                <Text>Select the most applicable job field</Text>
                                <Space>
                                    <Select
                                        defaultValue={selectedJobField.Parent ? selectedJobField.Parent : ''}
                                        className='parentJobFieldInput'
                                        onChange={handleSelectJobField}
                                    >
                                        {jobFieldsParents.map((parent, _) => (
                                            <Select.Option value={parent}>{parent}</Select.Option>
                                        ))}
                                    </Select>
                                </Space>
                            </>
                        )}

                        {showJobFields && (
                            <>
                                <Text>Select the most applicable field in {selectedParent} </Text>
                                <Space>
                                    <Select
                                        defaultValue={""}
                                        className='jobFieldInput'
                                        onChange={handleSelectJobField}
                                    >
                                        {filteredJobFields.map((field, _) => (
                                            <Select.Option value={field.Field}>{field.Field}</Select.Option>
                                        ))}
                                    </Select>
                                </Space>
                            </>
                        )}
                    </Space>
                </div>




                {!doNotDisplayOptions && (
                    <>
                        <Divider type="vertical" className="vertical-divider" />

                        <div className="job-actions">
                            <Tabs
                                onChange={onChange}
                                type="card"
                                style={{
                                    width: '100%',
                                    overflowX: 'auto',
                                    whiteSpace: 'nowrap',
                                }}
                                moreIcon={
                                    <span className="more-icon">
                                        <div className="more-icon-text">View</div>
                                        <div className="more-icon-text">More</div>
                                    </span>
                                }
                                items={jobCategories.map((category, index) => {
                                    const id = String(index + 1);
                                    return {
                                        label: category.category,
                                        key: id,
                                        children: (
                                            <div>
                                                <Space direction="vertical" size="large">
                                                    <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', gap: '3px 0' }}>
                                                        {category.jobs.map((job, jobIndex) => (
                                                            <Tag
                                                                key={jobIndex}
                                                                color="#108ee9"
                                                                onClick={() => updateUserData_TargetJob(job.title)}
                                                                className='jobTags'
                                                                onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#0d7bc1'}
                                                                onMouseLeave={(e) => e.currentTarget.style.backgroundColor = '#108ee9'}
                                                            >
                                                                {job.title}
                                                            </Tag>
                                                        ))}
                                                    </div>
                                                </Space>
                                            </div>
                                        ),
                                    };
                                })}
                            />
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}

export default TargetJob;