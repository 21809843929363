import React, { useContext } from "react";
import { Card, Row, Col, Button } from "antd";
import useIsMobile from "../../contexts/useIsMobile";
import { useStripe } from "@stripe/react-stripe-js";
import { UserContext } from "../../contexts/userContext";

const PricingTable = ({ sample }) => {

    const { user } = useContext(UserContext);

    const userSubscriptionStatus = user?.subscription?.status;
    const userSubscriptionTier = user?.subscription?.tier.toLowerCase();

    const stripe = useStripe();
    const isMobile = useIsMobile();

    const pricingData = [
        {
            title: "Basic",
            price: "Forever Free",
            features: [
                "Job Tracker",
                "Application Guidance",
                "Professional Relationship Manager (PRM)",
                "Job Search Guidance",
                "Resume Builder & Manager",
                "Basic Candidacy Evaluation",
                "25 Premium Opportunities/Week",
            ],
            buttonText: "Choose Basic",
            tier: "free",
            priceId: "price_1QYxfDEIXZ5v9gRWL08jzyEu", // Basic (9.99) Price ID: price_1QXTzREIXZ5v9gRWpocv2Gxr - Now Set to Free  
        },
        // {
        //     title: "Pro",
        //     price: <><del>$25/month</del> Free</>,
        //     features: [
        //         "Everything in Basic",
        //         "AI Resume & Cover Letter Generator",
        //         "Network Message Generator",
        //         "Basic Candidacy Evaluation",
        //         "25 Premium Opportunities/Week"
        //     ],
        //     buttonText: "Choose Pro",
        //     tier: "Pro",
        //     priceId: "price_1QXTzPEIXZ5v9gRWBRUmUw9J"
        // },
        {
            title: "Premium",
            price: <><del>$19/Week</del> $9/Week</>,
            features: [
                <strong>Everything in Basic</strong>,
                "One Click Auto-Apply",
                "Premium Insider Connections",
                "Network Message Generator",
                "Advanced Candidacy Evaluation",
                "AI Resume & Cover Letter Generator",
                "50 Premium Opportunities/Week",
            ],
            buttonText: "Choose Premium",
            tier: "Premium",
            priceId: "price_1QYxjZEIXZ5v9gRWzSCE9IkU" //Old Price ID: price_1QXTzKEIXZ5v9gRW8SQrEBLJ
        },
    ];

    // Sort pricingData to have the active tier first if isMobile is true
    const sortedPricingData = isMobile
        ? [...pricingData].reverse()
        : pricingData;

    // Find the maximum number of features
    const maxFeatures = Math.max(...sortedPricingData.map(tier => tier.features.length));

    // Pad the features array with empty strings to match the length of the longest features array
    const paddedPricingData = sortedPricingData.map(tier => ({
        ...tier,
        features: [...tier.features, ...Array(maxFeatures - tier.features.length).fill("-")]
    }));

    // Calculate the column span based on the number of pricing tiers
    const colSpan = Math.floor(24 / pricingData.length);

    const ensureStripeCustomerId = async (email) => {
        const response = await fetch("/stripe/create-customer", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ email }),
        });

        console.log(response);

        const { customerId } = await response.json();

        return customerId;
    };

    const handleCheckout = async (priceId) => {
        if (!priceId) {
            alert("You selected the free plan!");
            return;
        }

        var customerId = user.subscription.stripeCustomerId || "";
        if (!customerId) {
            customerId = await ensureStripeCustomerId(user.email);
        }


        try {
            const response = await fetch("/stripe/create-subscription", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    customerId,
                    priceId,
                }),
            });

            if (!response.ok) {
                const errorData = await response.json();
                console.error("Backend error:", errorData.error);
                return;
            }

            const { id: sessionId } = await response.json();

            // Redirect to Stripe Checkout
            const result = await stripe.redirectToCheckout({ sessionId });

            if (result.error) {
                console.error("Stripe Checkout Error:", result.error.message);
            }
        } catch (error) {
            console.error("Checkout error:", error);
        }
    };

    const handlePortal = async () => {
        try {
            // Ensure the user has a Stripe customer ID
            let customerId = user.subscription.stripeCustomerId || "";
            if (!customerId) {
                customerId = await ensureStripeCustomerId(user.email); // Implement this function if needed
            }

            // Call your backend to create a portal session
            const response = await fetch('/stripe/create-portal-session', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    customerId,
                    returnUrl: window.location.origin + '/profile/billing' // Specify the return URL
                }),
            });

            if (!response.ok) {
                throw new Error('Failed to create portal session.');
            }

            const { url } = await response.json();

            // Redirect to the Stripe Customer Portal
            window.location.href = url;
        } catch (error) {
            console.error('Error redirecting to the portal:', error.message);
        }
    };


    var selectedIndex = isMobile ? 0 : 2;


    return (
        <div style={{ padding: "20px" }}>
            <Row gutter={[16, 16]} justify="center">
                {paddedPricingData.map((tier, index) => (
                    <Col xs={24} md={colSpan} key={index} style={{ display: 'flex', justifyContent: 'center' }}>
                        <Card
                            title={tier.title}
                            bordered={false}
                            style={{
                                textAlign: "center",
                                flex: 1,
                                display: 'flex',
                                flexDirection: 'column',
                                // justifyContent: 'space-between',
                                border: index == selectedIndex && sample == true ? '2px solid #1890ff' :
                                        userSubscriptionStatus == "active" && userSubscriptionTier && tier && userSubscriptionTier.toLowerCase() == tier.title.toLowerCase() ? '2px solid #1890ff' :
                                        userSubscriptionTier && tier && userSubscriptionTier.toLowerCase() === tier.tier.toLowerCase() && userSubscriptionTier.toLowerCase() === "free" ? '2px solid #1890ff' :
                                        '1px solid #d9d9d9',
                                backgroundColor: userSubscriptionStatus == "active" ? userSubscriptionTier.toLowerCase() == tier.title.toLowerCase() ? 'white' : '#f5f5f5' : 'white',
                                color: userSubscriptionStatus == "active" ? userSubscriptionTier.toLowerCase() == tier.title.toLowerCase() ? 'inherit' : '#bfbfbf' : 'inherit',
                                opacity: userSubscriptionStatus == "active" ? userSubscriptionTier.toLowerCase() == tier.title.toLowerCase() ? 1 : 0.6 : 1,
                                pointerEvents: userSubscriptionStatus == "active" ? userSubscriptionTier.toLowerCase() == tier.title.toLowerCase() ? 'auto' : 'none' : 'auto',
                            }}
                            actions={!sample && (
                                [
                                    userSubscriptionTier && tier && userSubscriptionTier.toLowerCase() === tier.tier.toLowerCase() && userSubscriptionTier.toLowerCase() === "free" ? ( //Users "tier" not "title"
                                        <Button
                                            disabled
                                            type="primary"
                                            className="button-color-premium"
                                            style={{ textAlign: 'center', marginRight: '0px' }}
                                        >
                                            Selected
                                        </Button>
                                    ) : (
                                        <Button
                                            onClick={userSubscriptionStatus === "active" ? handlePortal : () => handleCheckout(tier.priceId)}
                                            type="primary"
                                            className="button-color-premium"
                                            style={{ textAlign: 'center', marginRight: '0px' }}
                                        >
                                            {userSubscriptionStatus === "active" && userSubscriptionTier.toLowerCase() === tier.title.toLowerCase() ? "Manage" : tier.buttonText}
                                        </Button>
                                    )
                                ]
                            )}
                        >
                            <div style={{ flex: 'none' }}>
                                <p style={{ fontSize: "24px", fontWeight: "bold", margin: 0 }}>{tier.price}</p>
                            </div>
                            <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                                <ul style={{ listStyleType: "none", padding: 0, margin: 0, flex: 'none' }}>
                                    {tier.features.map((feature, idx) => (
                                        <li style={{ marginBottom: "10px" }} key={idx}>{feature}</li>
                                    ))}
                                </ul>
                            </div>
                        </Card>
                    </Col>
                ))}
            </Row>
        </div>
    );;
};

export default PricingTable;