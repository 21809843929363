import React, { useState, useEffect, useRef, useContext } from 'react';
import { Button, Modal, Input, Typography, Spin, Checkbox } from 'antd';
import { notification, Space } from 'antd';
import { MailOutlined, CheckCircleOutlined } from '@ant-design/icons';
import AutoComplete from 'antd/lib/auto-complete';
import { title_followUp, defaultFollowUpMessage, additionalInfoFollowUpPlaceholder, fetchFollowUpMessage, calculateDaysPending, deafultFollowUpSubject } from './Messages/follow-up.js';
import { title_network, defaultLinkNetworkMessage, additionalInfoNetowrkPlaceholder, fetchLinkNetworkMessage } from './Messages/network.js';
import { title_thankYou, defaultThankYouMessage, additionalInfoThankYouPlaceholder, fetchThankYouMessage, defaultThankYouSubject } from './Messages/thank-you.js';
import { title_accept, defaultAcceptanceMessage, additionalInfoAcceptancePlaceholder, fetchAcceptanceMessage, defaultAcceptanceSubject } from './Messages/accept.js';
import { title_reject, defaultRejectMessage, additionalInfoRejectPlaceholder, fetchRejectMessage, defaultRejectSubject } from './Messages/reject.js';
import { title_feedback, defaultFeedbackMessage, additionalInfoFeedbackPlaceholder, fetchFeedbackMessage, defaultFeedbackSubject } from './Messages/feedback.js';
import { useQuery } from '@apollo/client';
import { QUERY_MY_CONTACT_NAMES } from '../../../utils/queries';
import { QUERY_COMPANY_BY_NAME } from '../../../utils/queries';
import { useMutation } from '@apollo/client';
import { ADD_CONTACT } from '../../../utils/mutations';
import { UPDATE_APPLICATION_FOLLOWUP_OR_NETWORK } from '../../../utils/mutations';
import { ADD_JOB_POSTING_W_DATE_TO_CONTACT } from '../../../utils/mutations';
import { ADD_COMPANY } from "../../../utils/mutations.js";
import { SAVE_CONTACT } from "../../../utils/mutations.js";
import { SAVE_COMPANY } from '../../../utils/mutations';
import { useTour } from '../../../contexts/tourContext.js';
import EmailCompose from '../../MailBox/EmailComposer/index.js';
import useIsMobile from '../../../contexts/useIsMobile';
import { fetchSendEmail } from '../../../utils/EmailAPIs/sendEmail.js';
import { UserContext } from '../../../contexts/userContext.js'
import FindConnectionsModal from '../FindConnectionsModal';


const { Title, Text } = Typography;

const MessageModal = ({ onClose, app, messageType, updateNetwork, updateFollowUp, updateThankYou, updateAcceptance, updateDecline, updateFeedback, viewFindConnections, findConnectionsParam }) => {
  const { user } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [additonalInfo, setAdditionalInfo] = useState('');
  const [message, setMessage] = useState('')
  const [messageTitle, setMessageTitle] = useState('');
  const [messagePlaceholder, setMessagePlaceholder] = useState('');
  const { data, refetch } = useQuery(QUERY_MY_CONTACT_NAMES);
  const [contacts, setContacts] = useState();
  const [contactOptions, setContactOptions] = useState([]);
  const [currentContact, setCurrentContact] = useState('');
  const [fullcurrentContact, setFullCurrentContact] = useState({});
  const [addContact] = useMutation(ADD_CONTACT);
  const [updateApplication] = useMutation(UPDATE_APPLICATION_FOLLOWUP_OR_NETWORK);
  const [addJobPostingToContact] = useMutation(ADD_JOB_POSTING_W_DATE_TO_CONTACT);
  const companyName = app?.job?.company || findConnectionsParam;
  const { data: companyData } = useQuery(QUERY_COMPANY_BY_NAME, {
    variables: { companyName: companyName }
  });
  const [companyID, setCompanyID] = useState();
  const [addCompany] = useMutation(ADD_COMPANY);
  const [saveContact] = useMutation(SAVE_CONTACT);
  const [saveCompany] = useMutation(SAVE_COMPANY);
  const [api, contextHolder] = notification.useNotification();
  const [coldOutreach, setColdOutreach] = useState(false)
  const [linkedInCharacterLimit, setLinkedInCharacterLimit] = useState(false)
  const [newSubject, setNewSubject] = useState('Looking to connect')
  const isMobile = useIsMobile();
  const [findConnectionsVisible, setFindConnectionsVisible] = useState(viewFindConnections);

  var appsCompany = app?.job?.company || "";
  var appsPosition = app?.job?.position || "";
  var appsID = app?._id || "";


  const { setTourSteps, startTour } = useTour();
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);
  const ref5 = useRef(null);
  const ref6 = useRef(null);
  const ref7 = useRef(null);

  useEffect(() => {
    if (companyData) {
      console.log("checking for ID");
      //Do not set company ID if object is empty (null)
      if (companyData.singleCompanyByName == null) {
        console.log("company does not exist");
      } else {
        console.log("company exist");
        setCompanyID(companyData.singleCompanyByName._id);
        //TODO check if company is already saved to user's company
      }
    }
  }, [companyData]);

  useEffect(() => {
    // Define the tour steps
    setTourSteps([
      {
        target: () => ref1.current,
        title: 'Enter Contact Name',
        description: 'Enter the name of the contact you would like to send the message to.'
      },
      {
        target: () => ref2.current,
        title: 'Quick Add Contact',
        description: 'If the contact does not exist, click the Quick Add button to add the contact.'
      },
      {
        target: () => ref3.current,
        title: 'Search for Contact',
        description: 'If you need to find the contact, click the Find Contact button. This will open a new tab to LinkedIn.',
      },
      {
        target: () => ref4.current,
        title: 'Enter Additional Information',
        description: 'Enter any additional information you would like to include in the message.',
      },
      {
        target: () => ref5.current,
        title: 'Generate Message',
        description: 'Click the Generate Message button to generate the message based on the information provided.',
      },
      {
        target: () => ref6.current,
        title: 'Copy Message',
        description: 'Click the Copy Message button to copy the generated message to your clipboard.',
      },
      {
        target: () => ref7.current,
        title: 'Mark As Sent',
        description: 'Click the Mark As Sent button to mark the message as sent and save it to the contact.',
      },
    ]);
    //startTour();
  }, [ref1, setTourSteps]);

  useEffect(() => {
    // Check if it's the user's first visit to the OptimizeResume page
    if (!localStorage.getItem('hasUsedMessageModal')) {
      // Start the tour
      startTour();
      // Set 'hasVisitedOptimizeResume' to 'true' in local storage
      localStorage.setItem('hasUsedMessageModal', 'true');
    }
  }, []);


  const openNotificationWithIcon = (type, mes, description) => {
    api[type]({
      message: mes,
      description: description,
    });
  };

  useEffect(() => {
    if (data) {
      setContacts(data.myContacts);
    }
    if (messageType === title_followUp) {
      setMessage(defaultFollowUpMessage(currentContact, appsPosition, appsCompany));
      setMessageTitle(title_followUp);
      setMessagePlaceholder(additionalInfoFollowUpPlaceholder);
      setNewSubject(deafultFollowUpSubject);
    }
    if (messageType === title_network) {
      setMessage(defaultLinkNetworkMessage(currentContact, appsPosition, appsCompany));
      setMessageTitle(title_network);
      setMessagePlaceholder(additionalInfoNetowrkPlaceholder);
    }
    if (messageType === title_thankYou) {
      setMessage(defaultThankYouMessage(currentContact, appsPosition, appsCompany));
      setMessageTitle(title_thankYou);
      setMessagePlaceholder(additionalInfoThankYouPlaceholder);
      setNewSubject(defaultThankYouSubject);
    }
    if (messageType === title_accept) {
      setMessage(defaultAcceptanceMessage(currentContact, appsPosition, appsCompany));
      setMessageTitle(title_accept);
      setMessagePlaceholder(additionalInfoAcceptancePlaceholder);
      setNewSubject(defaultAcceptanceSubject);
    }
    if (messageType === title_reject) {
      setMessage(defaultRejectMessage(currentContact, appsPosition, appsCompany));
      setMessageTitle(title_reject);
      setMessagePlaceholder(additionalInfoRejectPlaceholder);
      setNewSubject(defaultRejectSubject);
    }
    if (messageType === title_feedback) {
      setMessage(defaultFeedbackMessage(currentContact, appsPosition, appsCompany));
      setMessageTitle(title_feedback);
      setMessagePlaceholder(additionalInfoFeedbackPlaceholder);
      setNewSubject(defaultFeedbackSubject);
    }
  }, [currentContact, messageType, appsPosition, appsCompany, data]);

  useEffect(() => {
    refetch()
  }, [findConnectionsVisible]);

  const fetchMessage = async () => {
    var followUpMes = ""

    if (messageType === title_followUp) {
      followUpMes = await fetchFollowUpMessage(appsPosition, appsCompany, additonalInfo, currentContact, coldOutreach, linkedInCharacterLimit);
    }
    if (messageType === title_network) {
      followUpMes = await fetchLinkNetworkMessage(appsPosition, appsCompany, additonalInfo, currentContact, coldOutreach, linkedInCharacterLimit);
    }
    if (messageType === title_thankYou) {
      followUpMes = await fetchThankYouMessage(appsPosition, appsCompany, additonalInfo, currentContact, coldOutreach, linkedInCharacterLimit);
    }
    if (messageType === title_accept) {
      followUpMes = await fetchAcceptanceMessage(appsPosition, appsCompany, additonalInfo, currentContact, coldOutreach, linkedInCharacterLimit);
    }
    if (messageType === title_reject) {
      followUpMes = await fetchRejectMessage(appsPosition, appsCompany, additonalInfo, currentContact, coldOutreach, linkedInCharacterLimit);
    }
    if (messageType === title_feedback) {
      followUpMes = await fetchFeedbackMessage(appsPosition, appsCompany, additonalInfo, currentContact, coldOutreach, linkedInCharacterLimit);
    }

    setMessage(followUpMes);
  };

  const handleOk = async () => {
    setLoading(true);
    try {
      await fetchMessage();
    } catch (error) {
      console.error("Error fetching the follow-up message:", error);
    } finally {
      setLoading(false);
    }
  };

  const copyMessageToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(message);
      // Optionally, you can set a state or display a notification to inform the user that the text has been copied
      console.log("Message copied to clipboard!");
    } catch (err) {
      console.error("Failed to copy text: ", err);
    }
  };

  const handleSearch = (value) => {
    if (contacts) {
      const filteredContacts = contacts.filter(contact => {
        const fullName = contact.lastName ? `${contact.contactDetails.firstName} ${contact.contactDetails.lastName}` : contact.contactDetails.firstName;
        const companyName = contact.contactDetails.company ? contact.contactDetails.company.companyName : '';
        const NameWithCompany = `${fullName} ${companyName}`;
        return NameWithCompany.includes(value);
      });
      const contactOptions = filteredContacts.map(contact => ({ value: contact.contactDetails.lastName ? `${contact.contactDetails.firstName} ${contact.contactDetails.lastName}, ${contact.contactDetails.company?.companyName || ''}` : `${contact.contactDetails.firstName}, ${contact.contactDetails.company?.companyName || ''}`, id: contact.id }));
      setContactOptions(contactOptions);
    }
  };

  const handleSelectContact = (value) => {
    const [name] = value.split(','); // Split the value by comma and take the first part
    setCurrentContact(name.trim()); // Remove any leading or trailing spaces
    // Find the contact object from the contacts array
    const selectedContact = contacts.find(contact => {
      const fullName = contact.contactDetails.lastName ? `${contact.contactDetails.firstName} ${contact.contactDetails.lastName}` : contact.contactDetails.firstName;
      const companyName = contact.contactDetails.company ? contact.contactDetails.company.companyName : '';
      const NameWithCompany = `${fullName}, ${companyName}`;
      return NameWithCompany == value;
    });
    setFullCurrentContact(selectedContact);
  };

  const handleAddContact = async () => {

    var currentCompanyID = companyID;

    if (!currentContact) {
      console.log("Please enter a contact name");
      return;
    }

    //If company ID does not exist, create a new company
    if (!currentCompanyID) {
      try {
        const { data } = await addCompany({
          variables: {
            companyName: appsCompany,
            postingStatus: "Private",
          },
        });
        console.log("Added company");
        currentCompanyID = data.addCompany._id;
        setCompanyID(currentCompanyID);

        // Save the company to the user's companies
        await saveCompany({
          variables: {
            company: currentCompanyID,
          },
        });
      } catch (error) {
        console.error("Error adding company:", error);
      }
    }

    const contactName = currentContact.split(' ');
    if (contactName.length > 1) {
      var firstName = contactName[0];
      var lastName = contactName[1];
    } else {
      var firstName = currentContact;
      var lastName = '';
    }

    const newContact = await addContact({
      variables: {
        firstName: firstName,
        lastName: lastName,
        company: { _id: currentCompanyID },
      }
    });

    // Save the contact to the user's contacts
    await saveContact({
      variables: {
        contact: newContact.data.addContact._id,
        relationship: "Other",
      },
    });

    console.log(newContact.data.addContact._id);

    refetch();

  };

  const isContactAdded = () => {
    if (contacts) {
      const filteredContacts = contacts.filter(contact => `${contact.contactDetails.firstName}${contact.contactDetails.lastName ? ' ' + contact.contactDetails.lastName : ''}` === currentContact);
      if (filteredContacts.length > 0) {
        return true;
      }
    }
    return false;
  };

  const markAsSent = async () => {

    if (isContactAdded()) {
      const filteredContacts = contacts.filter(contact => `${contact.contactDetails.firstName}${contact.contactDetails.lastName ? ' ' + contact.contactDetails.lastName : ''}` === currentContact);
      await addJobPostingToContact({
        variables: {
          id: filteredContacts[0]._id,
          relatedJobPosting: app.job._id,
          lastContacted: new Date().toISOString().slice(0, 10),
          lastContactMessage: message,
        }
      });
      console.log("Added job posting to contact");
    } else {
      openNotificationWithIcon('error', 'Contact does not exist', 'Please add the contact first');
      console.log("Contact does not exist, need to add first");
      return;
    }

    if (messageType === title_followUp) {
      await updateApplication({
        variables: {
          id: appsID,
          dateFollowedUp: new Date().toISOString().slice(0, 10),
        }
      });
      console.log("Marked as followed up");
      updateFollowUp(new Date().toISOString().slice(0, 10));
    }
    if (messageType === title_network) {
      await updateApplication({
        variables: {
          id: appsID,
          dateNetworked: new Date().toISOString().slice(0, 10),
        }
      });
      console.log("Marked as networked");
      updateNetwork(new Date().toISOString().slice(0, 10));
    }
    if (messageType === title_thankYou) {
      await updateApplication({
        variables: {
          id: appsID,
          dateThankYouSent: new Date().toISOString().slice(0, 10),
        }
      });
      console.log("Marked as thank you");
      updateThankYou(new Date().toISOString().slice(0, 10));
    }
    if (messageType === title_accept) {
      await updateApplication({
        variables: {
          id: appsID,
          dateAcceptedOffer: new Date().toISOString().slice(0, 10),
        }
      });
      updateAcceptance(new Date().toISOString().slice(0, 10));
      console.log("Marked as accepted");
    }
    if (messageType === title_reject) {
      await updateApplication({
        variables: {
          id: appsID,
          dateDeclinedOffer: new Date().toISOString().slice(0, 10),
        }
      });
      updateDecline(new Date().toISOString().slice(0, 10));
      console.log("Marked as rejected");
    }
    if (messageType === title_feedback) {
      await updateApplication({
        variables: {
          id: appsID,
          dateRequestedFeedback: new Date().toISOString().slice(0, 10),
        }
      });
      updateFeedback(new Date().toISOString().slice(0, 10));
      console.log("Marked as feedback");
    }

    openNotificationWithIcon('success', 'Message Sent', 'Message has been marked as sent and saved to contact');
    onClose();
    console.log("Marked as sent");
  };

  const findContact = () => {

    setFindConnectionsVisible(true);

    // if (messageTitle === title_followUp) {
    //   window.open(`https://www.linkedin.com/search/results/people/?keywords=${app.job.company} Talent`, '_blank');
    // } else {
    //   window.open(`https://www.linkedin.com/search/results/people/?keywords=${app.job.company}`, '_blank');
    // }
  };

  const sendEmail = async () => {
    try {
      //Send email

      console.log(fullcurrentContact);

      var email = fullcurrentContact?.contactDetails?.email || fullcurrentContact.email

      const response = await fetchSendEmail(email, user.customUserEmail, newSubject, message, fullcurrentContact._id, user._id);

      // Check if the response is an error
      if (response instanceof Error || !response) {
        console.log("Error sending email:", response);
        throw response;
      }

      notification.success({
        message: 'Email Sent',
        description: 'Your email has been sent successfully.',
        placement: 'topRight',
        duration: 10,
      });

      markAsSent();
    } catch (error) {

      if (email === undefined || email === null || email === '') {
        notification.error({
          message: 'Error Sending Email',
          description: 'Contact is missing an email address.',
          placement: 'topRight',
          duration: 10,
        });
      } else {

        notification.error({
          message: 'Error Sending Email',
          description: 'Error sending email. Please try again later.',
          placement: 'topRight',
          duration: 10,
        });
      }
    }

  };


  return (
    <>
      {contextHolder}
      <Modal
        visible
        title=""
        onOk={handleOk}
        // style={{ width: "100%" }} // Adjust the width of the modal
        onCancel={onClose}
        footer={[
          !findConnectionsVisible && (
          <div key="buttons" style={{ textAlign: 'center' }}>
            <Button ref={ref5} className='button-color-premium' loading={loading} onClick={handleOk}>
              Generate Message
            </Button>
            {/* <Button ref={ref6} onClick={copyMessageToClipboard}>Copy Message</Button> */}
            <Button disabled={loading} ref={ref7} onClick={markAsSent}>Mark As Sent</Button>
            <Button className='button-color' style={{ marginTop: isMobile ? '5px' : 0 }} disabled={loading} onClick={sendEmail} icon={<MailOutlined />}>
              Send Email
            </Button>
          </div>
          )
        ]}
      >
        {!findConnectionsVisible
          ? (
            <>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '20px', marginBottom: '10px' }}>
                <Title level={4}><MailOutlined /> {messageTitle}</Title>
                {messageType === title_followUp && <Text>{`Days Pending: ${calculateDaysPending(app.dateApplied)} days`}</Text>}
              </div>
              {/* <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
          <Button onClick={findContact}>
            Find Contact
          </Button>
        </div> */}
              <div ref={ref1} style={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}>
                <AutoComplete
                  options={contactOptions}
                  style={{ width: '70%' }}
                  onSearch={handleSearch}
                  onSelect={handleSelectContact}
                  onChange={(value) => setCurrentContact(value)}
                  value={currentContact}
                >
                  <Input.Search size="medium" placeholder="Contact's Name" />
                </AutoComplete>
                {isContactAdded() ? (
                  <CheckCircleOutlined style={{ color: 'green' }} />
                ) : (
                  <Button ref={ref2} onClick={handleAddContact}>Quick Add</Button>
                )}
                <Button ref={ref3} onClick={findContact} className='button-color-premium'>
                  Find Contacts
                </Button>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', marginBottom: '10px' }}>
                <Checkbox onChange={(e) => setColdOutreach(e.target.checked)}>Cold Outreach</Checkbox>
                <Checkbox onChange={(e) => setLinkedInCharacterLimit(e.target.checked)}>LinkedIn Character Limit</Checkbox>
              </div>

              <EmailCompose
                newSubject={newSubject}
                setNewSubject={setNewSubject}
                newBody={message}
                setNewBody={setMessage}
                messageRows={8} />

              {/* <div ref={ref4}>
          <Input.TextArea
            rows={4}
            placeholder={messagePlaceholder}
            onChange={(e) => setAdditionalInfo(e.target.value)}
          />
        </div>
        <div style={{
          marginTop: '15px',
          marginBottom: '15px',
        }}>
          {loading ? <Spin /> :

            <Input.TextArea
              rows={8}
              value={message}
              onChange={(e) => setMessage(e.target.value)}

            />}
        </div> */}

            </>
          ) : (
            <FindConnectionsModal visible={findConnectionsVisible} setVisible={setFindConnectionsVisible} company={app?.job?.company || findConnectionsParam} contactName={setCurrentContact} fullContact={setFullCurrentContact}/>
          )}


      </Modal>
    </>
  );
};

export default MessageModal;