import React, { useState, useEffect } from "react";
import JobPostingsMatchCard from "../../components/Cards/JobPostingsMatchCard";
import { useParams, Link } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { QUERY_SINGLE_JOB_POSTING } from '../../utils/queries';
import Auth from '../../utils/auth';
import logo from '../../assets/logo.png';
import { Card, Layout, Button } from "antd";
import Sider from "antd/es/layout/Sider";
import { Content } from "antd/es/layout/layout";
import Eval from '../../assets/Eval.png';
import useIsMobile from '../../contexts/useIsMobile';

const JobPostingPage = () => {
    const isMobile = useIsMobile();
    const { id } = useParams();
    console.log(id);
    const { loading, data, error } = useQuery(QUERY_SINGLE_JOB_POSTING, { variables: { id } });
    const [currentJobPosting, setCurrentJobPosting] = useState(null);

    useEffect(() => {
        if (data) {
            setCurrentJobPosting(data.singleJobPosting);
        }
    }, [data]);

    console.log("Data:", data);
    console.log("Error:", error);
    console.log("Current Job Posting:", currentJobPosting);

    console.log(Auth.loggedIn())

    if (loading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return <p>Error: {error.message}</p>;
    }

    if (!currentJobPosting) {
        return <p>No job posting found.</p>;
    }

    return (
        <div>
          {!Auth.loggedIn() && (
            <header className="flex-row px-1">
              <div className="logo">
                <h1 className="Hiremize-Title">
                  <Link to="/" onClick={(event) => {
                    event.preventDefault();
                    window.location.href = '/';
                  }}>
                    <img src={logo} alt="HireMize" />
                  </Link>
                </h1>
              </div>
            </header>
          )}
          {isMobile ? (
            <div style={{ display: 'flex', flexDirection: 'column', margin: '10px' }}>
              {!Auth.loggedIn() && ( <Card title="Evaluate Your Candidacy" style={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginBottom: '4px', padding: '10px' }}>
                <div style={{ textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <p>Evaluate your suitability for this position. Click the button below to begin your assessment.</p>
                  <img src={Eval} alt="Evaluate Your Candidacy" style={{ width: '40%', height: 'auto', marginBottom: '10px' }} />
                  <Button className="button-color">
                    <Link to="/signup" style={{ color: 'inherit', textDecoration: 'none' }}>
                      Begin Evaluation
                    </Link>
                  </Button>
                </div>
              </Card> )}
              <Content style={{ backgroundColor: "white", flex: 1, marginTop: '10px', marginBottom: '10px' }}>
                <JobPostingsMatchCard match={currentJobPosting} forJobPostingPage={true} />
              </Content>
              {!Auth.loggedIn() && ( <Card title="Explore More Jobs" style={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: '20px' }}>
                <div style={{ textAlign: 'center' }}>
                  <p>Discover new opportunities that match your skills and interests. Click the button below to explore more jobs.</p>
                  <Button className="button-color">
                    <Link to="/signup" style={{ color: 'inherit', textDecoration: 'none' }}>
                      Explore Jobs
                    </Link>
                  </Button>
                </div>
              </Card> )}
            </div>
          ) : (
            <Layout style={{ display: 'flex', height: 'auto', backgroundColor: "white" }}>
                {!Auth.loggedIn() && (
                <>
              <Sider width="25%" style={{ backgroundColor: "white", display: 'flex', flexDirection: 'column', margin: '10px' }}>
                <Card title="Evaluate Your Candidacy" style={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', minHeight: '47.5%', marginBottom: '16px', padding: '20px' }}>
                  <div style={{ textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <p>Evaluate your suitability for this position. Click the button below to begin your assessment.</p>
                    <img src={Eval} alt="Evaluate Your Candidacy" style={{ width: '40%', height: 'auto', marginBottom: '10px' }} />
                    <Button className="button-color">
                      <Link to="/signup" style={{ color: 'inherit', textDecoration: 'none' }}>
                        Begin Evaluation
                      </Link>
                    </Button>
                  </div>
                </Card>
                <Card title="Explore More Jobs" style={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', minHeight: '47.5%', padding: '20px' }}>
                  <div style={{ textAlign: 'center' }}>
                    <p>Discover new opportunities that match your skills and interests. Click the button below to explore more jobs.</p>
                    <Button className="button-color">
                      <Link to="/signup" style={{ color: 'inherit', textDecoration: 'none' }}>
                        Explore Jobs
                      </Link>
                    </Button>
                  </div>
                </Card>
              </Sider>
                 </>
                )}
              <Content style={{ backgroundColor: "white", flex: 1, marginTop: '10px', marginBottom: '10px' }}>
                <JobPostingsMatchCard match={currentJobPosting} forJobPostingPage={true} />
              </Content>
            </Layout>
          )}
        </div>
      );
};

export default JobPostingPage;
