import axios from 'axios';



export const title_thankYou = "Thank-You";

export const defaultThankYouSubject = "Thank You for the Interview";

export function defaultThankYouMessage(Name, Position, Company) {
    if (!Name) {
        Name = "[Name]";
    }

    return `Hi ${Name},

I wanted to take the time to thank you for the opportunity to interview for the ${Position} position at ${Company}. I appreciate your time and consideration in interviewing me.

I am very interested in the role and believe my skills and experience would be a great fit. I look forward to the possibility of working with you and your team.

Thank you once again for considering my application. Please feel free to reach out if you need any additional information.

Best regards,
[Your Name]
`;
}

export const additionalInfoThankYouPlaceholder = "Add any details on why you would be a good fit for the job from the interview";

export const fetchThankYouMessage = async (Position, Company, AdditionalInfo, currentContact, coldOutreach, linkedInCharacterLimit) => {
    const data = {
        Position: Position,
        Company: Company,
        AdditionalInfo: AdditionalInfo,
        Name: currentContact,
        Cold: coldOutreach,
        LinkLimit: linkedInCharacterLimit,
        MessageType: "Thank-You"
    };
    try {
        const response = await axios.post('/message/applicationMessage', data);

        return response.data.applicationMessage;
    } catch (error) {
        console.error("There was a problem with the fetch operation:", error.message);
    }
};
