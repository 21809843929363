import React, { useState, useEffect, useContext, useRef } from 'react';
import { startFindConnectionsTask, getFindConnectionsTaskStatus, getFindConnectionsTaskResult } from '../../../utils/FindConnectionsAPIs/findConnections';
import { Button, Modal, Input, Typography, Spin, Dropdown, Menu, Space, Checkbox, Tooltip, notification, Row, Col, List } from 'antd';
import { backgroundJobCheck } from '../../../utils/helpers/background';
import { LinkedinFilled, MailOutlined, SaveOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { SAVE_CONTACT } from "../../../utils/mutations.js";
import { UserContext } from '../../../contexts/userContext.js';

const close = () => {
    console.log(
        'Notification was closed. Either the close button was clicked or duration time elapsed.',
    );
};


const { Text } = Typography;

const FindConnectionsModal = ({ visible, setVisible, company, contactName, fullContact }) => {

    const [contactResults, setContactResults] = useState([]);
    const [loading, setLoading] = useState(false);
    const [saveContact] = useMutation(SAVE_CONTACT);
    const { user } = useContext(UserContext);
    const [api, contextHolder] = notification.useNotification();

    const openNotificationWithIcon = (type, mes, description) => {
        const key = `open${Date.now()}`;
        const btn = (
            <Space>
                <Button type="primary" size="small" onClick={() => api.destroy(key)}>
                    Got It!
                </Button>
            </Space>
        );
        api[type]({
            message: mes,
            description: description,
            duration: 0,
            btn,
            key,
            onClose: close,
        });
    };


    const handleFindConnections = async () => {

        try {

            setLoading(true);

            let taskResponse = await startFindConnectionsTask(company, user._id);
            let taskId = taskResponse.data.taskID;
            // check the status of the task and get the result
            let response = await backgroundJobCheck(getFindConnectionsTaskStatus, getFindConnectionsTaskResult, taskId);
            let result = response.result;

            if (result) {
                console.log("Connections Found", result);
                setContactResults(result);
            } else {
                notification.error({
                    message: 'Error',
                    description: 'Could not find any connections at this time. Please try again later.',
                });
            }

            setLoading(false);

        } catch (error) {

            if (error.message === 'Subscription limit exceeded') {
                openNotificationWithIcon('error', 'Subscription Limit Exceeded', 'Please upgrade your subscription to continue using this feature.');
            } else {
                console.error("There was a problem finding connections", error.message);
            }


        } finally {
            setLoading(false);
        }

    }

    useEffect(() => {
        if (visible) {
            handleFindConnections();
        }
    }, [visible]);

    const handleSelectedContact = async (contact) => {
        console.log('Selected Contact:', contact);
        contactName(contact.firstName + ' ' + contact.lastName);
        fullContact(contact);

        //Save Contact to User's Contacts
        await saveContact({
            variables: {
                contact: contact._id,
                relationship: "Other",
            }
        });

        setVisible(false);
        console.log('closed');
    }

    const openLinkedInSearch = () => {
        window.open(`https://www.linkedin.com/search/results/people/?keywords=${company}`, '_blank');
    };



    return (
        // <Modal
        //     title="Find Connections"
        //     visible={visible}
        //     onCancel={() => setVisible(false)}
        //     footer={[
        //         // <Button key="back" onClick={() => setVisible(false)}>
        //         //     Close
        //         // </Button>,
        //     ]}
        // >
        <>
        <Space>{contextHolder}</Space>
        <Space direction="vertical" style={{ width: '100%' }}>

            {/* <Button onClick={() => setVisible(false)}>Emailer</Button> */}
            <Link onClick={() => setVisible(false)} style={{ position: 'relative', top: 1, left: 1, fontSize: '0.75em' }}>&#8592; To Emailer</Link>

            {loading && <Spin />}

            {contactResults.length > 0 ? (

                <>
                    <Text style={{ fontSize: '1.5em' }} strong>Connections Found</Text>

                    <List
                        itemLayout="horizontal"
                        dataSource={contactResults}
                        renderItem={item => (
                            <List.Item
                                actions={[
                                    <Button key="mail" onClick={() => handleSelectedContact(item)} icon={<MailOutlined />}>
                                        Save & Contact
                                    </Button>,
                                    // <Button key="save" icon={<SaveOutlined />} />,
                                ]}
                            >
                                <List.Item.Meta
                                    title={`${item.firstName} ${item.lastName}`}
                                    description={
                                        <div>
                                            {item.jobTitle}
                                            {item.linkedinProfile && (
                                                <a href={item.linkedinProfile} target="_blank" rel="noopener noreferrer" style={{ marginLeft: '10px' }}>
                                                    <LinkedinFilled />
                                                </a>
                                            )}
                                        </div>
                                    }
                                />
                            </List.Item>
                        )}

                    />

                </>
            ) : (
                !loading &&
                (
                    <>
                        <Text style={{ fontSize: '1.5em' }} strong>No contacts found at this time</Text>
                        <Button type="primary" className="button-color" onClick={openLinkedInSearch} style={{ marginTop: '10px' }}>
                            Search on LinkedIn
                        </Button>
                    </>
                )
            )}

        </Space>
        </>
        // </Modal>
    );

}

export default FindConnectionsModal;