import axios from 'axios';



export const title_reject = "Rejection";

export const defaultRejectSubject = "Rejection of Job Offer";

export function defaultRejectMessage(Name, Position, Company) {
    if (!Name) {
        Name = "[Name]";
    }

    return `Hi ${Name},

I am writing to formally decline the offer for the ${Position} position at ${Company}. After careful consideration, I have decided that this is not the right opportunity for me at this time.

I appreciate the time and effort you and your team have spent considering my application and conducting the interview process. I am grateful for the opportunity to learn more about your company and meet your team.

I hope that we may cross paths again in the future. Thank you once again for considering me for this position.

Best regards,
[Your Name]
`;
}

export const additionalInfoRejectPlaceholder = "Add any details on why you are rejecting the job offer";

export const fetchRejectMessage = async (Position, Company, AdditionalInfo, currentContact, coldOutreach, linkedInCharacterLimit) => {
    const data = {
        Position: Position,
        Company: Company,
        AdditionalInfo: AdditionalInfo,
        Name: currentContact,
        Cold: coldOutreach,
        LinkLimit: linkedInCharacterLimit,
        MessageType: "Rejection"
    };
    try {
        const response = await axios.post('/message/applicationMessage', data);

        return response.data.applicationMessage;
    } catch (error) {
        console.error("There was a problem with the fetch operation:", error.message);
    }
}
