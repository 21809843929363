import axios from 'axios';



export const title_followUp = "Follow-Up";

export const deafultFollowUpSubject = "Following Up On My Application";

export function defaultFollowUpMessage(Name, Position, Company) {

    if (!Name) {
        Name = "[Hiring Manager’s Name]";
    }

    return `Hi ${Name},

I hope all is well. I know how busy you probably are, but I recently applied to the ${Position} position, and wanted to check in on your decision timeline. I am excited about the opportunity to join ${Company} and help [bring in new clients/develop world-class content/anything else awesome you would be doing] with your team.

Please let me know if it would be helpful for me to provide any additional information as you move on to the next stage in the hiring process.

I look forward to hearing from you,
   
[Your Name]`
}

export const additionalInfoFollowUpPlaceholder = "Add any details on why you would be a good fit for the job";

//Copy Pasted Code from Application Item
export const calculateDaysPending = (dateApplied) => {
    const today = new Date(); // Current date
    const appDate = new Date(parseInt(dateApplied, 10)); // Convert 'dateApplied' to a Date object
    // Calculate the time difference in milliseconds
    const timeDifference = today - appDate;
    // Calculate the number of days by dividing the time difference by the number of milliseconds in a day (24 * 60 * 60 * 1000)
    const daysDifference = Math.floor(timeDifference / (24 * 60 * 60 * 1000));
    return daysDifference;
  };

export const fetchFollowUpMessage = async (Position, Company, AdditionalInfo, currentContact, coldOutreach, linkedInCharacterLimit) => {
    const data = {
        Position: Position,
        Company: Company,
        AdditionalInfo: AdditionalInfo,
        Name: currentContact,
        Cold: coldOutreach,
        LinkLimit: linkedInCharacterLimit
    };
    try {
        const response = await axios.post('/message/followup', data);

        return response.data.followUpMes;
    } catch (error) {
        console.error("There was a problem with the fetch operation:", error.message);
    }
};
